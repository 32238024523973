import { getClasses } from '@pasqal/core/helpers/styles';

import type { TComponentWithChildrenAndHTMLAttributes } from '@pasqal/core/ui/components/types';

import './container.css';

type TSize = 'xs' | 'sm' | 'md' | 'lg' | 'full';
interface IProps
  extends TComponentWithChildrenAndHTMLAttributes<HTMLDivElement> {
  size?: TSize;
  className?: string;
  hasGutters?: boolean;
}

interface IStyles {
  size: Record<TSize, string>;
}

const styles: IStyles = {
  size: {
    xs: 'Container--xs',
    sm: 'Container--sm',
    md: 'Container--md',
    lg: 'Container--lg',
    full: 'Container--full'
  }
};

export const Container = ({
  size = 'md',
  hasGutters = true,
  children,
  className,
  ...rest
}: IProps) => {
  const css = getClasses([
    'Container',
    styles.size[size],
    hasGutters && 'Container--withGutters',
    className
  ]);
  return (
    <div className={css} {...rest}>
      {children}
    </div>
  );
};
